<template>
  <div class="base-info">
    <add-form></add-form>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import AddForm from './cpns/add-form/add-form'
export default defineComponent({
  props: {

  },
  components: {
    AddForm
  },
  setup() {


    return {

    }

  }
})
</script>

<style scoped lang="less">
.base-info{
  margin-top: 30px;
}
</style>




