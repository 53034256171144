<template>
  <div class="format-form">
    <div>
      <el-input size="small" v-model="ruleForm.paraName" placeholder="规格名"></el-input>
    </div>
    <div>
      <el-input size="small" v-model="ruleForm.paraDetail" placeholder="规格值"></el-input>
    </div>
    <div>
      <el-input size="small" v-model="ruleForm.salePrice" placeholder="价格">
        <template #suffix>| 元</template>
      </el-input>
    </div>
    <div class="promPrice">
      <el-input size="small" v-model="ruleForm.promPrice" placeholder="划线价">
        <template #suffix>| 元</template>
      </el-input>
      <el-button color="#409EFF" size="small" v-if="isFirst" @click="handleAddClick" type="primary" plain> 新增</el-button>
      <el-button size="small" v-else @click="handleRemoveClick" type="danger" plain>删除</el-button>

    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'

export default defineComponent({
  props: {
    isFirst: {
      type: Boolean,
      default: false
    },
    ruleForm: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  components: {

  },
  emits: ['addFormatFormChange', 'removeFormatFormChange'],
  setup(props, { emit }) {

    const handleAddClick = () => {
      emit('addFormatFormChange')
    }

    const handleRemoveClick = () => {
      emit('removeFormatFormChange')
    }

    return {
      handleAddClick,
      handleRemoveClick
    }

  }
})
</script>

<style scoped lang="less">
.format-form {
  max-width: 1000px;
  min-width: 400px;
  display: flex;
  margin: 16px 0;
  padding: 0 10px;

  &>div {
    flex: 1;
    margin-right: 10px;
  }

  &:deep(.el-select) {
    max-width: 160px;


  }

  &:deep(.el-input) {
    max-width: 160px;
  }

  .promPrice {
    display: flex;
    align-items: center;
    flex: 1.3;

    &:deep(.el-button) {
      margin-left: 10px;
    }
  }

  .add,
  .remove {
    font-size: 12px;
    text-align: center;
    cursor: pointer;
  }




}
</style>
