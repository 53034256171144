<template>
  <div class="goods-add">
    <breadcrumb :breadcrumbList="breadcrumbList"></breadcrumb>
    <div class="add-content">
      <el-tabs v-model="activeName" class="tabs" @tab-click="handleClick">
        <el-tab-pane name="baseInfo" disabled label="第一步：编辑基本信息">
          <base-info></base-info>
        </el-tab-pane>
        <el-tab-pane name="editDetail" disabled label="第二步：编辑服务详情">
          <goods-edit></goods-edit>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import { breadcrumbList } from './config'
import BaseInfo from './cpns/base-info/base-info.vue'
import GoodsEdit from './cpns/goods-edit/goods-edit.vue'
import emitter from '@/utils/eventbus'
export default defineComponent({
  props: {

  },
  components: {
    Breadcrumb,
    BaseInfo,
    GoodsEdit
  },
  setup() {
    const activeName = ref('baseInfo')
    emitter.on('addGoodsBtnNextChange', () => {
      activeName.value = 'editDetail'
    })
    emitter.on('addGoodsBtnPreviousChange', () => {
      activeName.value = 'baseInfo'
    })

    return {
      breadcrumbList,
      activeName
    }

  }
})
</script>

<style scoped lang="less">
.goods-add {
  min-width: 980px;

  .add-content {
    margin: 20px;
    padding: 20px;
    background-color: #fff;
  }
}
</style>




